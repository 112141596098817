import $ from 'blingblingjs';

const [header] = $('.main-header');
const [html] = $('html');
const [body] = $('body');
const [collapse] = $('.menu-collapse');
const [toggle] = $('.menu-toggle');
const links = $('.menu-link');
const submenuLinks = $('.submenu-link');
const lastSubmenuLinks = $('.submenu-item:last-child a');

const cssKeys = {
    active: 'is-active',
    overflow: 'overflow',
    open: 'is-open',
};

/**
 * Opens main menu
 */
const open = () => {
    toggle.classList.add(cssKeys.active);
    toggle.setAttribute('aria-expanded', true);
    collapse.classList.add(cssKeys.active);
    html.classList.add(cssKeys.overflow);
    body.classList.add(cssKeys.overflow);
    header.classList.add(cssKeys.open);
};

/**
 * Closes main menu
 */
const close = () => {
    toggle.classList.remove(cssKeys.active);
    toggle.setAttribute('aria-expanded', false);
    collapse.classList.remove(cssKeys.active);
    html.classList.remove(cssKeys.overflow);
    body.classList.remove(cssKeys.overflow);
    header.classList.remove(cssKeys.open);
};

/**
 * Handles click on menu icon
 */
const handleClickOnMenuToggle = (e) => {
    // stop default acton
    e.preventDefault();
    // is menu open?
    const isOpened = toggle.getAttribute('aria-expanded') === 'true';
    // menu is closed, open it
    if (!isOpened) {
        open();
        return;
    }
    // menu is opened, close it
    close();
};

/**
 *   Closes opened submenu
 */
const closeOpenedSubmenu = () => {
    // select opened submenu
    const [openedSubmenu] = $(`.submenu.${cssKeys.active}`);
    // remove active class
    if (openedSubmenu) openedSubmenu.classList.remove(cssKeys.active);
    // close opened menu link
    const [openedMenuLink] = $('.menu-link[aria-expanded="true"]');
    if (openedMenuLink) openedMenuLink.setAttribute('aria-expanded', false);
};

/**
 * Handles focus event
 * @param {Event} e event focus
 */
const handleFocus = (e) => {
    // get a link
    const link = e.currentTarget;
    // has the link submenu?
    const submenu = link.nextElementSibling;

    if (submenu) {
        // set link as opened
        link.setAttribute('aria-expanded', true);
        // open submenu
        submenu.classList.add('is-active');
    }
};

/**
 *   Handles touch on menu link
 *    @param {Event} e event focus
 */
const handleTouch = (e) => {
    // close opepened submenu
    // closeOpenedSubmenu();
    // has the link submenu?
    const submenu = e.target.nextElementSibling;

    if (submenu) {
        // prevent reload
        e.preventDefault();
        // toogle submenu active state
        submenu.classList.toggle('is-active');
        // toogle link opened/closed state
        const isOpened = e.target.getAttribute('aria-expanded') === 'true';
        e.target.setAttribute('aria-expanded', !isOpened);
    }
};

// Event handlers
links.on('focus', handleFocus);
links.on('touchstart', handleTouch);

submenuLinks.on('touchstart', handleTouch);
lastSubmenuLinks.on('blur', closeOpenedSubmenu);

toggle.on('click', handleClickOnMenuToggle);

document.addEventListener('click', closeOpenedSubmenu);
